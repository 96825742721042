<template>
  <form>
    <b-form-select v-model="churchKey" :options="churchesForSelection" :plain="true"
      @change="handleSelectChange($event)" class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0">
      <template slot="first">
        <option :value="null" disabled>{{ i18n['quick-jump'].tcSelectAnotherChurch }}</option>
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quick-jump',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump': {
            tcSelectAnotherChurch: 'Select another church',
          },
        }
      },
    },
  },
  data() {
    return {
      churchKey: null,
    }
  },
  computed: {
    ...mapGetters({
      churchesForSelection: 'churchMinistry/churchesForSelection',
    }),
  },
  methods: {
    ...mapActions({
      setSelectedChurchKey: 'user/setSelectedChurchKey',
    }),
    async handleSelectChange(evt) {
      await Promise.all([await this.setSelectedChurchKey(evt)]).then(() => {
        this.$emit('church_changed')
      })
    },
  },
}
</script>


<style/>
