<template>
  <div class="event">
    <div class="row">
      <div class="col-3 date" :class="{ future: futureDate, past: !futureDate }">
        <div>
          <span v-if="obj.fullDate">{{ returnFormattedDate(obj.fullDate, { month: 'short' }) }}</span>
          <span v-if="obj.day">{{ obj.day }}</span>
          <span v-if="obj.year">{{ obj.year }}</span>
        </div>
      </div>
      <div class="col-9 content">
        <h3 v-if="obj.title" :title="obj.title" class>
          {{ charLimit(objTitle, 25) }}
        </h3>
        <h3 v-if="obj.presenter" :title="obj.presenter" class="event-presenter">
          {{ obj.presenter }}
        </h3>
        <div class="text">
          <p :title="obj.content" v-if="obj.content">
            {{ charLimit(obj.content, 30) }}
          </p>
          <p v-if="obj.speaker">
            <span class="upper">{{ i18n['event'].tcSpeaker }}</span>: {{ obj.speaker }}
          </p>
          <p v-if="obj.status"></p>
          <p v-if="!!obj.status">
            <span :class="statusClass">{{ objStatus }}</span>
          </p>
          <p v-if="obj.offer">
            <span class="upper">{{ i18n['event'].tcOffering }}</span>: {{ obj.offer }}
          </p>
          <p v-if="obj.notes && obj.notes.length > 0">
            <span class="upper">{{ i18n['event'].tcNotes }}</span>: {{ obj.notes }}
          </p>
          <p v-if="!!obj.time && !!obj.weekday" class="time">
            {{ this.objDay }} - {{ i18n['event'].tcAt }} {{ obj.time }}
          </p>
          <p v-else-if="!!obj.weekday" class="time">{{ obj.objDay }}</p>
          <p v-if="obj.qualifiedFlag">
            <span class="upper">{{ i18n['event'].tcQualified }}</span>: {{ obj.qualifiedFlag }}
          </p>
          <p v-if="obj.mediaFlag">
            <span class="upper">{{ i18n['event'].tcMedia }}</span>: {{ obj.mediaFlag }}
          </p>
          <p v-if="obj.name">
            <span class="upper">{{ i18n['event'].tcContactedBy }}</span>: {{ obj.name }}
          </p>
          <p v-if="obj.nte_note">
            <span class="upper">{{ i18n['event'].tcNotes }}</span>: {{ charLimit(obj.nte_note, 60) }}
          </p>
          <p v-if="obj.coordinator">
            <span class="upper">{{ i18n['event'].tcCoordinator }}</span>: {{ charLimit(obj.coordinator, 60) }}
          </p>
          <p v-if="obj.start_time">{{ i18n['event'].tcStartsAt }} {{ obj.start_time }}</p>
        </div>
        <a v-if="obj.link" href="#" @click.prevent="handleLinkClick(obj, orgkey)">{{ i18n['event'].tcMore }}</a>
        <a v-if="obj.editLink" @click.prevent="handleEditClick" href="#">{{ i18n['event'].tcMore }}</a><span
          v-else><br /></span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import { empty_guid, meetings } from '@/json/data.json'
import calendarService from '@/services/calendarService'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'event',
  mixins: [calendarMixin, translationMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          event: {
            tcAt: 'at',
            tcContactedBy: 'Contacted By',
            tcCoordinator: 'Coordinator',
            tcMedia: 'Media',
            tcMore: 'More',
            tcNotes: 'Notes',
            tcOffering: 'Offering',
            tcQualified: 'Qualified',
            tcSpeaker: 'Speaker',
            tcStartsAt: 'Starts at',
          },
        }
      },
    },
    obj: Object,
    secure_view_keys: {},
    orgkey: '',
    ignore: false,
  },
  data() {
    return {
      objDay: '',
      objMonth: '',
      objNotes: '',
      objSpeaker: '',
      objStatus: '',
      objTitle: '',
    }
  },
  created() {
    this.handleUpdatedProps()
    this.updateObjStatus()
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    statusClass() {
      if (!!this.obj.status) {
        return (
          {
            pending: 'badge badge-info',
            complete: 'badge badge-success',
            incomplete: 'badge badge-danger',
          }[this.obj.status.toLowerCase()] || ''
        )
      }
    },
    futureDate() {
      if (!!this.obj.month) {
        return new Date(this.obj.fullDate) > new Date()
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapActions({
      clearSelected: 'eventCalendar/clearSelected',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async handleEditClick() {
      this.$emit('edit_requested', this.obj)
    },
    charLimit(text, limit) {
      if (text && typeof text === 'string') {
        return text.length > limit ? text.substring(0, limit - 3) + '...' : text
      }
    },
    async handleLinkClick(obj, orgkey) {
      if (!!obj.meeting || !!obj.key) {
        await this.setSelectedVisitKey(this.obj.meeting || this.obj.key)
      }
      let result = {}
      let meetingRec = {}
      let events = {}
      if (!orgkey) {
        //if no orgkey just pass along to the link in the object
        this.$router.push(obj.link)
        return
      }
      await calendarService
        .getMeetingSingle(orgkey, obj.key)
        .then((res) => {
          if (res.status === 200) {
            result = res.data
            if (!!res.data.meetings[0].mtg_mtr_key && res.data.meetings[0].mtg_mtr_key !== empty_guid) {
              calendarService.getMeetingRecurrence(res.data.meetings[0].mtg_mtr_key).then((res) => {
                if (res.status === 200) {
                  meetingRec = res.data
                  events = this.getEvent(result, meetingRec).then((evts) => {
                    this.clearSelected()
                    this.setSelectedMeeting(evts[0])
                  })
                  this.$router.push({
                    path: 'calendar/camp-calendar/event-detail',
                  })
                }
              })
            } else {
              events = this.getEvent(result, undefined).then(mtgs => this.nonRecurringEventNavigation(mtgs[0]))
            }
          }
        })
        .catch((error) => {
          console.error('error: ', error)
        })
    },
    translateObjectTitle(givenTitle) {
      this.objTitle = {
        'Facility Placement Date': this.i18n['event'].tcFacilityPlacementDate,
        'GideonCard Video Presentation': this.i18n['event'].tcGideonCardVideoPresentation,
        'Gideons Church Presentation': this.i18n['event'].tcGideonsChurchPresentation,
        'Last Presentation': this.i18n['event'].tcLastPresentation,
        'Last Restocking Visit': this.i18n['event'].tcLastRestockingVisit,
        'Last Visit': this.i18n['event'].tcLastVisit,
        'Next Presentation': this.i18n['event'].tcNextPresentation,
        'Next Video Presentation': this.i18n['event'].tcNextVideoPresentation,
      }[givenTitle]

      this.objTitle = !this.objTitle ? givenTitle : this.objTitle
    },
    handleUpdatedProps() {
      const { notes, speaker, title } = this.obj

      this.objNotes = {
        'No previous presentations are recorded': this.i18n['event'].tcNoPrevPresentations,
        'No presentation date set': this.i18n['event'].tcNoPresentationDateSet,
        'No previous visits are recorded': this.i18n['event'].tcNoPrevVisits,
      }[notes]

      this.objNotes = !this.objNotes ? notes : this.objNotes
      this.objSpeaker = speaker === 'No speaker assigned' ? this.i18n['event'].tcNoSpeakerAssigned : speaker

      this.translateObjectTitle(title)

      const fullDate = this.obj.fullDate
      let translatedWeekDay = new Date(fullDate).toLocaleString(this.prefCulture, { weekday: 'long' })
      let translatedMonth = new Date(fullDate).toLocaleString(this.prefCulture, { month: 'short' })

      this.objDay = translatedWeekDay ? translatedWeekDay : this.obj.weekday
      this.objMonth = translatedMonth ? translatedMonth : this.obj.month

      return true
    },
    async nonRecurringEventNavigation(event) {
      let destination = {}
      switch (event.meetingtype) {
        case meetings.gideonscardsshowing.value:
          await this.setSelectedVisitKey(event.id)
          destination.path = meetings.gideonscardsshowing.route
          break
        case meetings.gideonsscripturesplacement.value:
          await this.setSelectedPlacementDateKey(event.id)
          destination.path = meetings.gideonsscripturesplacement.route
          break
        case meetings.gideonrestockingvisit.value:
          await this.setSelectedVisitKey(event.id)
          destination.path = meetings.gideonrestockingvisit.route
          break
        case meetings.churchpresentation.value:
          await this.setSelectedMeetingKey(event.id)
          destination.path = meetings.churchpresentation.route
          break
        default:
          await this.clearSelected()
          await this.setSelectedMeeting(event)
          destination.name = 'cal-event-detail'
          break
      }
      this.$router.push(destination)
    },
    updateObjStatus() {
      if (!this.obj.status) return false
      const statusForSitecore = 'tc' + this.obj.status
      this.objStatus = this.i18n['event'][statusForSitecore] || this.obj.status
    },
  },
  watch: {
    i18n: function () {
      return this.handleUpdatedProps()
    },
  },
}
</script>

<style lang="scss" >
@import '@/styles/settings.scss';
@import '@/styles/components/component-event.scss';

.upper {
  text-transform: uppercase;
}

.event-presenter {
  font-size: 85% !important;
}

.future {
  background-image: linear-gradient(135deg, #0098c7 0%, #92cfeb 100%) !important;
}

.past {
  background-image: linear-gradient(135deg, #fbc301 0%, #ebdd92 100%) !important;
}
</style>
